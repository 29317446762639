<template>
  <div class="create-project">
    <form>
        <table>
            <tr height="50">
                <td ><span>Project Name:</span></td>
                <td><input type="text" v-model="project.pName"></td>
            </tr>
            <tr height="50">
                <td ><span>Project Type:</span></td>
                <td>
                    <span><input type="radio" name="p-type" value="1" v-model="project.ptype">Main</span>
                    <span><input type="radio" name="p-type" value="0" v-model="project.ptype"> Sub</span>
                </td>
            </tr>
             <tr height="50" v-if="project.ptype==0">
                <td><span>Users:</span></td>
                <td><input type="text" v-model="project.puser"></td>
            </tr>
            <tr height="50">
                <td colspan="2" ><button @click.prevent="createProject">Create Project</button></td>
            </tr>
         
         
        </table>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters} from "vuex"
export default {
    data(){
        return{
            project:{
            pName:'',
            ptype:null,

            puser:''
            }

        }

    },
    computed:{
        ...mapGetters(['getProject']),

        ders(){
            return this.$store.getters.getProject
        }
      
    },

    methods:{
        ...mapActions(['triggerCreateProject']),
       createProject(){       
            this.$store.dispatch('triggerCreateProject',this.project)       
    }
    
    }
};
</script>

<style scoped>
.create-project {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15%;
}
button{
    margin-left: 50%;
}
</style>